import { Component, Vue } from 'vue-property-decorator'
import FormDocument from '@/components/ResidencyForm/Document/Document.vue'
import http from '@/services/http'
import ResidencyDocument from '@/Models/ResidencyDocument'
import Search from '@/components/Search/Search.vue'
import router from '@/router'
import Crm from '@/Models/Crm'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import menuModule from '@/store/menu'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormDocument,
    Search,
    ModalDelete,
    Header,
    Animation,
  },
})
export default class ViewDocument extends Vue {
  showList = true
  data: Array<ResidencyDocument> = []
  objResidencyDocument: ResidencyDocument = new ResidencyDocument()
  action = 'add'
  member: Crm = new Crm()
  loading = false
  delete_modal_active = false
  baseUrl = ''
  currentPage = 1
  header: Array<any> = [
    {
      field: 'date_read',
      label: 'Fecha',
    },
    {
      field: 'comment',
      label: 'Comentario',
    },
    {
      field: 'file',
      label: 'Doc',
    },
  ]
  mounted() {
    this.getResidencyDocument()
    this.baseUrl = process.env.VUE_APP_API_URL
    ;(this.$refs.header as any).isViewPrincipal = false
  }

  async getResidencyDocument() {
    this.loading = true
    try {
      const id = menuModule.id_param
      if (id == null) throw router.push({ name: 'CCSS' }).catch(console.log)
      const res = await http.get(
        `/api/residency/arcr_residency_documents/${id}/`,
      )
      if (!res.data) throw new Error(res.data)
      if (res.data.documents != 'Esta residencia no tiene documentos') {
        console.log(res.data.documents)
        this.data = res.data.documents.map((obj: any) => ({
          ...obj,
          residency_id: id,
          date_read: obj.date_presented,
          file: obj.file != null ? obj.file.slice(1) : obj.file,
          date_presented: Helpers.dateParse(obj.date_presented),
          upload:
            obj.file != null
              ? new File(
                  ['foo'],
                  obj.file.substring(obj.file.lastIndexOf('/') + 1),
                )
              : null,
        }))
      }
      this.member = res.data.member
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  editObj(props: any) {
    this.objResidencyDocument = props
    this.showList = false
    this.action = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(
        `/api/residency/arcr_residency_documents/${this.objResidencyDocument.id}/`,
      )
      this.data = this.data.filter(
        (a: ResidencyDocument) => a.id !== this.objResidencyDocument.id,
      )
      this.delete_modal_active = false
      this.getResidencyDocument()
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objResidencyDocument = props
  }
  closeModal() {
    this.objResidencyDocument = new ResidencyDocument()
    this.delete_modal_active = false
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objResidencyDocument = new ResidencyDocument()
    this.showList = true
    this.action = 'add'
    await this.getResidencyDocument()
  }
  isData(data: any) {
    this.data = data
  }
}
